import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormikProvider } from 'formik';
import { useReactiveVar } from '@apollo/client';
import EmailField from '@fingo/lib/components/fields/EmailField';
import PasswordField from '@fingo/lib/components/fields/PasswordField';
import PhoneNumberField from '@fingo/lib/components/fields/PhoneNumberField';
import TextField from '@fingo/lib/components/fields/TextField';
import { LoadingButton } from '@mui/lab';
import Collapse from '@mui/material/Collapse';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import useGetCountry from '@fingo/lib/hooks/useGetCountryFromUrl';
import { PasswordStrength } from '../utils';
import { authEmailVar } from '../../graphql/reactive-variables';
import useRegisterForm from '../../hooks/useRegisterForm';

const STEPS = [
  'Ingresa tu email para registrarte',
  'Ingresa los siguientes datos',
];

const RegisterForm = ({ redirectTo = '/app/home', landingTag = null }) => {
  const authEmail = useReactiveVar(authEmailVar);
  const country = useGetCountry();
  const redireCTTo = useMemo(
    () => {
      // This is some quick ab testing to see if we should redirect to the rut form
      if (country?.name === 'Chile') {
        const shouldRedirectToRut = Math.random() < 0.2;
        return shouldRedirectToRut ? '/app/tell-us-your-rut' : redirectTo;
      }
      return redirectTo;
    },
    [country?.name, redirectTo],
  );
  const formik = useRegisterForm(redireCTTo, landingTag);
  const { isSubmitting, isValid } = formik;

  const currentStep = useMemo(() => (authEmail ? 1 : 0), [authEmail]);

  return (
    <>
      <MobileStepper
        variant="dots"
        position="static"
        steps={2}
        activeStep={authEmail ? 1 : 0}
        sx={{
          justifyContent: 'center',
        }}
      />
      <Typography align="center" mt="0 !important" mb="4px !important">
        {STEPS[currentStep]}
      </Typography>
      <FormikProvider value={formik}>
        <form
          id="sign-up-form"
          onSubmit={formik.handleSubmit}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EmailField variant="outlined" disabled={!!authEmail} />
          <Collapse in={!!authEmail} unmountOnExit sx={{ width: '100%' }}>
            <TextField name="firstName" label="Nombre" variant="outlined" />
            <TextField name="lastName" label="Apellido" variant="outlined" />
            <PhoneNumberField variant="outlined" />
            <PasswordField variant="outlined" />
            <PasswordStrength password={formik.values.password} />
          </Collapse>
          <LoadingButton
            id={authEmail ? 'register-button' : 'continue-button'}
            variant="form"
            color="primary"
            size="small"
            type="submit"
            disabled={isSubmitting || !isValid}
            loading={isSubmitting}
            sx={{ mb: authEmail ? 0 : 6.5 }}
            fullWidth
          >
            {authEmail ? 'Registrarse' : 'Siguiente'}
          </LoadingButton>
        </form>
      </FormikProvider>
    </>
  );
};

RegisterForm.propTypes = {
  landingTag: PropTypes.string,
  redirectTo: PropTypes.string,
};

RegisterForm.defaultProps = {
  landingTag: null,
  redirectTo: '/app/home',
};

export default React.memo(RegisterForm);
